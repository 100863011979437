import type { AppProps } from "next/app";
import { NextUIProvider, Container, createTheme } from "@nextui-org/react";
import { IntlProvider } from "react-intl";
import { ThemeProvider as NextThemesProvider } from "next-themes";

import "../styles/globals.css";
import "../styles/embla.css";

import Nav from "../components/nav";
import { darkTheme, lightTheme } from "../components/themes";
import Head from "next/head";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <IntlProvider locale="en" defaultLocale="en">
      <NextThemesProvider
        attribute="class"
        defaultTheme="system"
        value={{
          light: lightTheme.className,
          dark: darkTheme.className,
        }}
      >
        <NextUIProvider>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Head>
          <Container css={{ p: "$0", m: "$0", w: "100%", mw: "unset" }}>
            <Nav />
            <Component {...pageProps} />
          </Container>
        </NextUIProvider>
      </NextThemesProvider>
    </IntlProvider>
  );
}
