import { Button, Modal, Text, Badge } from "@nextui-org/react";
import { NextRouter, useRouter } from "next/router";
import { useState } from "react";
import { isLanguage, Language, LanguageInfo, LANGUAGES } from "../language";

export default function LangDropdown() {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const routerLocale = useLanguage(router);

  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);
  const closeHandler = () => setVisible(false);

  function changeLang(locale: string) {
    router.push({ pathname, query }, asPath, { locale });
    closeHandler();
  }

  return (
    <div>
      <Button auto flat color="default" onPress={handler}>
        {LanguageInfo[routerLocale].nativeName}
      </Button>
      <Modal
        closeButton
        animated={false}
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Choose the language
          </Text>
        </Modal.Header>
        <Modal.Body>
          {LANGUAGES.map((lang) => {
            const isCurrent = lang === routerLocale;
            return (
              <a href="#" key={lang} onClick={() => changeLang(lang)}>
                <Text b={isCurrent}>
                  {LanguageInfo[lang].nativeName}
                  {isCurrent ? (
                    <Badge css={{ marginLeft: "$4" }}>CURRENT</Badge>
                  ) : (
                    <></>
                  )}
                </Text>
              </a>
            );
          })}
        </Modal.Body>
      </Modal>
    </div>
  );
}

function useLanguage(router: NextRouter): Language {
  const { locale } = router;
  return toLanguage(locale);
}

function toLanguage(s: unknown): Language {
  if (isLanguage(s)) {
    return s;
  }
  return "en";
}
