import { Navbar, Text, Button, Container, Grid } from "@nextui-org/react";
import { useRouter } from "next/router";
import Link from "next/link";
import { Logo } from "./icons/logo";
import LangDropdown from "./langDropdown";

export default function Nav() {
  const router = useRouter();

  return (
    <Navbar isBordered variant="sticky" containerCss={{ mw: "unset" }}>
      <Navbar.Brand>
        <Logo size="32px" color="var(--nextui-colors-foreground)" />
        <Link href="/" style={{ color: "inherit" }}>
          <Text b color="inherit" hideIn="xs">
            &nbsp; Legacy Launcher
          </Text>
        </Link>
      </Navbar.Brand>
      <Navbar.Content>
        <LangDropdown />
      </Navbar.Content>
    </Navbar>
  );
}
