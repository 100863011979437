import parser from "accept-language-parser";
import { IncomingHttpHeaders } from "http";
import { useRouter } from "next/router";
import { TranslationDict } from "../i18n";

export const LANGUAGES = [
  "ru",
  "en",
  "tr",
  "pt-BR",
  "vi",
  "de",
  "it",
  "cs",
  "uk",
  "hi",
  "hu",
  "ko",
  "bg",
  "es",
  "lt",
  "id",
  "pl",
] as const;

export type Language = (typeof LANGUAGES)[number];

export const LanguageInfo: Record<Language, { nativeName: string }> = {
  ru: {
    nativeName: "русский",
  },
  en: {
    nativeName: "English",
  },
  tr: {
    nativeName: "Türkçe",
  },
  "pt-BR": {
    nativeName: "Português (Brasil)",
  },
  vi: {
    nativeName: "tiếng Việt",
  },
  de: {
    nativeName: "Deutsch",
  },
  it: {
    nativeName: "Italiano",
  },
  cs: {
    nativeName: "čeština",
  },
  uk: {
    nativeName: "українська",
  },
  hi: {
    nativeName: "हिंदी",
  },
  hu: {
    nativeName: "Magyar",
  },
  ko: {
    nativeName: "한국어",
  },
  bg: {
    nativeName: "български",
  },
  es: {
    nativeName: "español",
  },
  lt: {
    nativeName: "lietuvių",
  },
  id: {
    nativeName: "bahasa Indonesia",
  },
  pl: {
    nativeName: "polski",
  },
};

export type Languages = Record<Language, Partial<TranslationDict>> & {
  ru: TranslationDict;
  en: TranslationDict;
};

export type TranslationInfo = {
  lang: Language;
  messages: Partial<TranslationDict>;
};

export function isLanguage(l: unknown): l is Language {
  return LANGUAGES.includes(l as unknown as Language);
}

export function useLanguage(): Language {
  const { locale } = useRouter();
  if (isLanguage(locale)) {
    return locale;
  }
  return "en";
}

export async function loadLanguages(): Promise<Languages> {
  const languages = {} as unknown as Languages;
  for (const key of LANGUAGES) {
    languages[key] = await import(
      `../../lang/${key.replaceAll("-", "_")}.json`
    );
  }
  return JSON.parse(JSON.stringify(languages));
}

export async function loadTranslation({
  lang,
  loadedLanguages,
}: {
  lang: Language;
  loadedLanguages?: Languages;
}): Promise<TranslationInfo> {
  return {
    lang,
    messages: (loadedLanguages ?? (await loadLanguages()))[lang],
  };
}

export function pickLangFromHeaders(headers: IncomingHttpHeaders): Language {
  let selectedLanguage = pickLangFromAcceptHeader(headers["accept-language"]);
  if (!selectedLanguage && typeof headers["cf-ipcountry"] === "string") {
    selectedLanguage = pickLangFromCountry(headers["cf-ipcountry"]);
  }
  if (!selectedLanguage) {
    selectedLanguage = "en";
  }
  return selectedLanguage;
}

export function pickLangFromCountry(
  country: string | undefined
): Language | null {
  if (!country) {
    return null;
  }
  switch (country) {
    case "VI":
      return "vi";
    case "BR":
      return "pt-BR";
    case "TR":
      return "tr";
    case "DE":
    case "AT":
    case "CH":
      return "de";
    case "IT":
      return "it";
    case "RU":
    case "BY":
    case "KZ":
    case "UA":
      return "ru";
  }
  return null;
}

export function pickLangFromAcceptHeader(
  acceptLangHeader: string | undefined
): Language | null {
  if (!acceptLangHeader) {
    return null;
  }
  const languages = Array.from(LANGUAGES) as unknown as string[];
  for (const s of parser.parse(acceptLangHeader)) {
    if (languages.includes(s.code)) {
      return s.code as Language;
    }
    const withRegion = `${s.code}-${s.region}`;
    if (languages.includes(withRegion)) {
      return withRegion as Language;
    }
  }
  return null;
}
