export const Logo = ({ size, color }: { size: string; color: string }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 98.598 111.998"
    width={size}
    height={size}
    style={{
      fill: color,
      stroke: color,
    }}
  >
    <g
      style={{ strokeLinejoin: "round" }}
      transform="translate(-33.71941,-46.808057)"
    >
      <path
        style={{ fill: "none", strokeWidth: 12 }}
        d="M 38.71941,76.807525 V 126.8081 l 43.297144,24.99762 43.300416,-24.99951 V 76.807459 L 82.016602,51.80798 Z"
      />
      <path
        style={{ strokeWidth: 10 }}
        d="M 38.71941,76.807525 82.016554,101.80973 125.31697,76.807459 82.016602,51.80798 Z"
      />
    </g>
  </svg>
);
